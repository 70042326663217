'use client'
import {useEffect, useState} from "react";
import throttle from "@/app/utils/throttler";

export interface IData {
    throttleDelay?: number
    root?: EventTarget
    deps?: any[]
    withSingleTrigger?: boolean
}

const DEFAULT_DATA: IData = {
    root: global.window
}

export default function useUserInteraction(event: keyof WindowEventMap, callback: any, data: IData = DEFAULT_DATA): Function {

    const _callback = () => {
        callback()

        if (data.withSingleTrigger) unwatchEvent()
    }

    const throttledCallback = throttle(_callback, data.throttleDelay);
    const watchEvent = () => {
        (data.root || window).addEventListener(event, throttledCallback)
    }

    const unwatchEvent = () => {
        (data.root || window).removeEventListener(event, throttledCallback)
    }

    useEffect(() => {
        watchEvent()

        return () => {
            unwatchEvent()
        }
    }, data.deps || []);

    return () => unwatchEvent()
}
