'use client'

import React, {useEffect} from "react";
import { TYPE_TEXT_NUMBER } from "@/app/components/atomic/Input/AppInput";

// Prevent auto focus feature when showing only one text input in iOs safari
const blurFirstTypingInputOnLoad = () => {
    // const firstTypingInput = document.querySelector(`input[data-input-type="${TYPE_TEXT_NUMBER}"]`) as HTMLInputElement | null
    // if (firstTypingInput) {
    //     firstTypingInput.blur()
    // }
}

export default function PageLoadedHandler() {
    useEffect(() => {
        // blurFirstTypingInputOnLoad()
    }, [])
    return <></>
}
