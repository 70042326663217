'use client'

import Script from "next/script";
import {usePathname} from "next/navigation";
import React, {useEffect} from "react";

function ConditionalScript(
    {
        condition = null,
        children,
        id = null,
    }: {
        condition?: boolean | null,
        children: React.ReactNode,
        id?: string | null,
    }) {
    if (condition !== null && !condition) return null

    const scriptProps = {...id && {id}}
    return <Script {...scriptProps}>{children}</Script>
}

// Todo : Next/React Bus event ?
if (global.window) {
    window.dataLayer = window.dataLayer || []
}

export default function ConditionalResources() {
    const pathname = usePathname()

    return <>
        <Script id="facebook-pixel" strategy="afterInteractive">
            {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '3061189487345774');
        fbq('track', 'PageView');`}
        </Script>
    </>
}
