'server-only'
import {notFound} from "next/navigation";

export const BASE_API: string = `${process.env.NEXT_PUBLIC_RM_BASE_API}/api`
export const BASE_NEXT_API: string = `${process.env.NEXT_PUBLIC_RM_NEXT_URL}/api`
export const BASE_AUCTOR_API: string = `${BASE_API}/auctor/front`

export const ENDPOINTS = {
    GET_SLUGS: `${BASE_AUCTOR_API}/page/slugs`,
    GET_PAGE_CONFIG: `${BASE_AUCTOR_API}/page/config/by-slug?slug=%SLUG%`,
    GET_PAGE_CONTENT: `${BASE_AUCTOR_API}/page/content/by-slug?slug=%SLUG%`,
    GET_PAGE_COMMENTS: `${BASE_AUCTOR_API}/page/comments/by-slug?slug=%SLUG%`,
    GET_COMPARATOR_FILTERS: `${BASE_AUCTOR_API}/comparator/filters-config/%COMPARATOR_ID%`,
    GET_TOOL: `${BASE_AUCTOR_API}/tool/%TOOL_ID%`,
    GET_COMPARATOR_TEMPLATE: `${BASE_AUCTOR_API}/comparator/template-config/%COMPARATOR_ID%`,
    GET_COMPARATOR_DATA: `${BASE_AUCTOR_API}/comparator/offers-data/%COMPARATOR_ID%`,
    GET_COMPARATOR_INITIAL_DATA: `${BASE_NEXT_API}/auctor/initial-offers?slug=%SLUG%`,
    GET_RM_NOTATION: `${BASE_API}/reviews/get-notation`,
}

export async function getAuctorPageConfig(slug: string, cache: boolean = true) {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const response = await fetch(ENDPOINTS.GET_PAGE_CONFIG.replace('%SLUG%', slug), {cache: cachePolicy});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getComparatorTemplateConfig(comparatorId: number, cache: boolean = true) {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const response = await fetch(ENDPOINTS.GET_COMPARATOR_TEMPLATE.replace('%COMPARATOR_ID%', comparatorId?.toString()), {cache: cachePolicy})

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getComparatorFiltersConfig(comparatorId: number, cache: boolean = true) {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const response = await fetch(ENDPOINTS.GET_COMPARATOR_FILTERS.replace('%COMPARATOR_ID%', comparatorId?.toString()), {cache: cachePolicy});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getComparatorResultsData(comparatorId: number, cache: boolean = true) {
    const response = await fetch(ENDPOINTS.GET_COMPARATOR_DATA.replace('%COMPARATOR_ID%', comparatorId?.toString()));

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getComparatorInitialData(comparatorId: number, slug: string) {
    const endpoint = ENDPOINTS.GET_COMPARATOR_INITIAL_DATA
        .replace('%COMPARATOR_ID%', comparatorId?.toString())
        .replace('%SLUG%', slug)
    const response = await fetch(endpoint)

    if (!response.ok) {
        if (process.env.APP_ENV === 'prod') {
            return notFound()
        }

        return []
    }

    return response.json()
}

export async function getTool(toolId: number, cache: boolean = true) {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_TOOL.replace('%TOOL_ID%', toolId?.toString())
    const response = await fetch(url, {cache: cachePolicy});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getNotation() {
    const response = await fetch(ENDPOINTS.GET_RM_NOTATION, {cache: 'force-cache'});

    return response.json();
}

export async function getExistingAuctorSlugs() {
    const response = await fetch(ENDPOINTS.GET_SLUGS, {
        cache: 'no-store'
    })

    return response.json()
}
