'use client'

import {useEffect, useState} from "react";

export default function FooterLinks() {
    const defaultServices = {
        title: 'Nos services d\'assurance',
        links: [
            {
                text: 'Comparateur assurance',
                link: '/#assurance'
            },
            {
                text: 'Tous nos comparateurs',
                link:
                    '/guide/#comparateur'
            },
            {
                text: 'Mutuelle Santé',
                link:
                    '/comparateur-mutuelle-sante#sante'
            },
            {
                text: 'Assurance vie',
                link:
                    '/guide/assurance-vie'
            },
            {
                text: 'Assurance moto',
                link:
                    '/guide/assurance-moto'
            },
            {
                text: 'Changer d\'assurance auto ',
                link:
                    '/guide/assurance-auto/changer'
            },
            {
                text: 'Comparatif banque',
                link:
                    '/guide/banque'
            },
            {
                text: 'Crédit consommation',
                link:
                    '/guide/credit-consommation'
            },
            {
                text: 'Assurance loyer impayé ',
                link:
                    '/guide/assurance-loyer-impaye'
            },
            {
                text: 'Assurance dommages ouvrage',
                link:
                    '/guide/assurance-dommages-ouvrage'
            },
            {
                text: 'Résiliation assurance habitation',
                link:
                    '/guide/assurance-habitation/resiliation'
            },
        ]
    }

    const [services, setServices] = useState(defaultServices)

    const servicesLinksLists = [
        {
            urls: ['/comparateur-mutuelle-sante', '/guide/mutuelle-sante', '/guide/surcomplementaire-sante'],
            servicesLinks: {
                title: 'Les indispensables de la mutuelle santé',
                links: [
                    {
                        text: 'Mutuelle fonctionnaire',
                        link: '/guide/mutuelle-sante/fonctionnaires'
                    },
                    {
                        text: 'Mutuelle jeune',
                        link: '/guide/mutuelle-sante/jeunes'
                    },
                    {
                        text: 'Mutuelle TNS',
                        link: '/guide/mutuelle-sante/professions-liberales-tns'
                    },
                    {
                        text: 'Mutuelle expatrié',
                        link: '/guide/mutuelle-sante/expatries'
                    },
                    {
                        text: 'Résiliation mutuelle',
                        link: '/guide/mutuelle-sante/resilier'
                    },
                    {
                        text: 'Mutuelle dentaire',
                        link: '/guide/mutuelle-sante/mutuelle-dentaire'
                    },
                    {
                        text: 'Mutuelle orthodontie adulte',
                        link: '/guide/mutuelle-sante/mutuelle-orthodontie-adulte'
                    },
                    {
                        text: 'Surcomplémentaire dentaire',
                        link: '/guide/surcomplementaire-sante/dentaire'
                    },
                    {
                        text: 'Mutuelle optique',
                        link: '/guide/mutuelle-sante/mutuelle-optique'
                    },
                    {
                        text: 'Surcomplémentaire',
                        link: '/guide/surcomplementaire-sante'
                    },
                    {
                        text: 'Remboursement mutuelle',
                        link: '/guide/mutuelle-sante/remboursement'
                    },
                ]
            }
        },
        {
            urls: ['/simulation-assurance-pret-immobilier', '/guide/assurance-pret-immobilier'],
            servicesLinks: {
                title: 'Guide de l\'assurance de prêt',
                links: [
                    {
                        text: 'Loi lemoine assurance emprunteur',
                        link: '/guide/assurance-pret-immobilier/loi-lemoine#questionnaire_sante_assurance_pret'
                    },
                    {
                        text: 'Comparateur assurance emprunteur',
                        link: '/simulation-assurance-pret-immobilier'
                    },
                    {
                        text: 'Changement assurance emprunteur',
                        link: '/guide/assurance-pret-immobilier/changer'
                    },
                    {
                        text: 'Résiliation assurance prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/resilier'
                    },
                    {
                        text: 'Renegocier assurance prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/renegocier'
                    },
                    {
                        text: 'Taux assurance prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/taux#emprunteur'
                    },
                    {
                        text: 'Coût assurance prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/cout'
                    },
                    {
                        text: 'Calcul assurance emprunteur',
                        link: '/guide/assurance-pret-immobilier/calcul'
                    },
                    {
                        text: 'Assurance emprunteur senior',
                        link: '/guide/assurance-pret-immobilier/senior'
                    },
                    {
                        text: 'Assurance prêt pour jeune',
                        link: '/guide/assurance-pret-immobilier/jeune'
                    },
                    {
                        text: 'Assurance chômage prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/chomage'
                    },
                    {
                        text: 'Assurance emprunteur diabete',
                        link: '/guide/assurance-pret-immobilier/diabete'
                    },
                    {
                        text: 'Emprunter avec un risque aggravé de santé',
                        link: '/guide/assurance-pret-immobilier/risque-aggrave'
                    },
                    {
                        text: 'Garantie PTIA',
                        link: '/guide/assurance-pret-immobilier/ptia'
                    },
                    {
                        text: 'Assurance décès prêt immobilier',
                        link: '/guide/assurance-pret-immobilier/deces'
                    },
                ]
            }
        },
    ]

    useEffect(() => {
        const pageUrl = window.location.pathname
        servicesLinksLists.map(servicesLinksList => {
            servicesLinksList.urls.map(url => {
                if (pageUrl.startsWith(url) && pageUrl !== '/') {
                    setServices(servicesLinksList.servicesLinks)
                }
            })
        })
    }, []);

    return <>
        <div className="flex-col flex">
            <div className={"mb-4 lg:mb-6 font-semibold text-lg"}>
                {services.title}
            </div>

            <div className={"grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 gap-x-5 gap-y-2 lg:gap-y-3"}>
                {services.links.map((service, index) => (
                    <a
                        className={'hover:underline text-sm'}
                        key={index}
                        href={service.link}
                    >
                        {service.text}
                    </a>
                ))}
            </div>
        </div>
    </>
}
