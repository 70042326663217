// Throttle will ignore events emitted during [throttleDelay]
export default function throttle(callback: Function, throttleDelay = 500) {
    let isThrottled = false

    return () => {
        if (!isThrottled) {
            callback()
            isThrottled = true

            setTimeout(() => {
                isThrottled = false
            }, throttleDelay)
        }
    };
}

// Debounce will wait [debounceDelay] before calling the callback
export function debounce(callback: Function, debounceDelay = 500) {
    let timeout: NodeJS.Timeout

    return () => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
                callback()
        }, debounceDelay)
    }
}
