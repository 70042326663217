'use client'
import React from "react"
import BaseHeader, {IBaseHeader} from "@/app/components/base/BaseHeader";

// TODO : Header as server component
interface IHeader extends IBaseHeader {
}

export default function Header(props: IHeader) {
    return <BaseHeader
        start={["Logo"]}
        center={["Nav"]}
        end={["Actu"]}
        shouldDetach
        {...props}
    />
}
