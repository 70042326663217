'use client'
import BaseHeader, {IBaseHeader} from "@/app/components/base/BaseHeader";

interface IComparatorHeader extends IBaseHeader {
}

export default function ComparatorHeader(props: IComparatorHeader) {
    return (
       <BaseHeader
           {...props}
           classes={'px-5 mx-auto'}
           shouldDetach={false}
           start={props.start || ["CatchPhrase"]}
           center={props.center || ["Logo"]}
           end={props.end || ["CallBlock"]}
       ></BaseHeader>
    )
}
