'use client'

import ReactDOM from "react-dom"

const REL_PREFETCH = 'dns-prefetch'
const REL_PRECONNECT = 'preconnect'

const loaders = {
    [REL_PREFETCH]: {
        callback: ReactDOM.prefetchDNS,
    },
    [REL_PRECONNECT]: {
        callback: ReactDOM.preconnect,
        options: { crossOrigin: 'anonymous' }
    },
}

export default function PreloadResources() {
    preloadedUrls.forEach(url => {
        url.loaders.forEach(loader => {
            const args: any[] = [
                url.link,
                (loaders as any)[loader].options || null,
            ].filter(arg => arg);

            (loaders as any)[loader].callback(...args)
        })
    })

    return null
}

const preloadedUrls = [
    {
        link: 'https://static.doubleclick.net',
        loaders: [REL_PREFETCH],
    },
    {
        link: '//d2wy8f7a9ursnm.cloudfront.net',
        loaders: [REL_PREFETCH],
    },
    {
        link: '//cdn.jsdelivr.net',
        loaders: [REL_PREFETCH],
    },
]
